$(document).ready(function() {
    var swiper = new Swiper(".client-list", {
        slidesPerView: 3,
        spaceBetween: 10,
        slidesPerGroup: 1,
        loop: false,
        loopFillGroupWithBlank: true,
        autoplay: {
            delay: 5000,
        },
        breakpoints: {
            576: {
                slidesPerView: 3,
                spaceBetween: 10
            },
            576: {
                slidesPerView: 3,
                spaceBetween: 10
            },
            768: {
                slidesPerView: 4,
                spaceBetween: 15
            },
            992: {
                slidesPerView: 5,
                spaceBetween: 30
            },

        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });
    // var swiper = new Swiper('.mySwiper', {
    //     slidesPerView: 1,
    //     spaceBetween: 10,
    //     // Responsive
    //     breakpoints: {
    //         576: {
    //             slidesPerView: 2,
    //             spaceBetween: 20
    //         },
    //         992: {
    //             slidesPerView: 3,
    //             spaceBetween: 30
    //         },
    //
    //     },
    //     navigation: {
    //         nextEl: '.swiper-button-next',
    //         prevEl: '.swiper-button-prev',
    //     },
    //     // direction: getDirection(),
    //     // on: {
    //     //     resize: function() {
    //     //         swiper.changeDirection(getDirection());
    //     //     }
    //     // },
    // });

    // function getDirection() {
    //     var windowWidth = window.innerWidth;
    //     var direction = window.innerWidth <= 760 ? 'vertical' : 'horizontal';

    //     return direction;
    // }

    var list = new Swiper('.list-cus-say', {
        slidesPerView: 1,
        spaceBetween: 10,
        // direction: getDirection(),
        // Responsive
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 30
            },

        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        // on: {
        //     resize: function() {
        //         list.changeDirection(getDirection());
        //     }
        // }
    });

    // read more
    var showChar = 100;
    var ellipsestext = "...";
    var moretext = "read more";
    var lesstext = "read less";
    $('.more').each(function() {
        var content = $(this).html();
        if (content.length > showChar) {
            var c = content.substr(0, showChar);
            var h = content.substr(showChar, content.length - showChar);

            var html = c + '<span class="moreellipses">' + ellipsestext + '&nbsp;</span><span class="morecontent"><span>' + h + '</span>&nbsp;&nbsp;<a href="" class="morelink">' + moretext + '</a></span>';
            $(this).html(html);
        }
    });
    $(".morelink").click(function() {
        if ($(this).hasClass("less")) {
            $(this).removeClass("less");
            $(this).html(moretext);
        } else {
            $(this).addClass("less");
            $(this).html(lesstext);
        }
        $(this).parent().prev().toggle();
        $(this).prev().toggle();
        return false;
    });


    var showChar = 170;
    var ellipsestext = "...";
    var moretext = "read more";
    var lesstext = "read less";
    $('.read-company').each(function() {
        var content = $(this).html();
        if (content.length > showChar) {
            var c = content.substr(0, showChar);
            var h = content.substr(showChar, content.length - showChar);

            var html = c + '<span class="moreellipses">' + ellipsestext + '&nbsp;</span><span class="morecontent-company"><span>' + h + '</span>&nbsp;&nbsp;<a href="" class="morelink-company">' + moretext + '</a></span>';
            $(this).html(html);
        }
    });
    $(".morelink-company").click(function() {
        if ($(this).hasClass("less")) {
            $(this).removeClass("less");
            $(this).html(moretext);
        } else {
            $(this).addClass("less");
            $(this).html(lesstext);
        }
        $(this).parent().prev().toggle();
        $(this).prev().toggle();
        return false;
    });

})
